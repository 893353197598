import {
  type ClientLoaderFunctionArgs,
  json,
  redirect,
  useLoaderData,
} from '@remix-run/react';

import { writeGuestUserAccess } from '../components/GameV2/apis/GuestUser';
import { writeOrgMasqueradeFallback } from '../components/GameV2/apis/OrgMasqueradeFallback';
import { deriveEscapeData, InAppEscape } from '../components/InAppEscape';
import { apiService } from '../services/api-service';
import {
  deleteKnownRedirectParam,
  getKnownRedirectParam,
} from '../utils/redirect-to';
import {
  isForbidden,
  isNotFound,
  tokenWithRedirect,
  type TokenWithRedirectOpts,
} from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const url = new URL(action.request.url);
  // NOTE: if you add a param here, you'll probably want to clean it up before
  // redirecting to the overworld!
  const asOrgId = getKnownRedirectParam(url.searchParams, 'as-org-id');
  const guestUsers = getKnownRedirectParam(url.searchParams, 'guest-users');

  const inAppData = deriveEscapeData(url);
  if (inAppData) return json(inAppData);

  const id = action.params.id;
  if (!id) {
    throw new Error('expected gamepack id');
  }

  try {
    const opts: TokenWithRedirectOpts = {
      requireAuthentication: true,
      preferRedirect: guestUsers === 'enabled' ? 'guest' : 'login',
    };

    await tokenWithRedirect(
      () => apiService.auth.verify(),
      action.request.url,
      opts
    );

    if (guestUsers === 'enabled') {
      writeGuestUserAccess(true);
    }

    if (asOrgId) {
      // resolve Org
      try {
        await tokenWithRedirect(
          () => apiService.organization.getOrganization(asOrgId),
          action.request.url,
          opts
        );
        // write org id so we know who to masquerade as on subsequent pages
        writeOrgMasqueradeFallback(asOrgId);
      } catch (e) {
        // swallow...if this fails, we won't masquerade.
      }
    }

    // redirect to the overworld
    const destination = new URL(action.request.url);
    destination.pathname = `/game-packs/${id}/overworld`;
    deleteKnownRedirectParam(destination.searchParams, 'as-org-id');
    deleteKnownRedirectParam(destination.searchParams, 'guest-users');
    // These are not used in this flow, but once were for SCORM flows. Clean
    // them up regardless.
    deleteKnownRedirectParam(destination.searchParams, 'learner-id');
    deleteKnownRedirectParam(destination.searchParams, 'learner-name');
    throw redirect(destination.toString());
  } catch (e) {
    if (isForbidden(e) || isNotFound(e)) {
      // treat both errors as a 404.
      throw json({}, { status: 404 });
    } else {
      throw e;
    }
  }
};

export function Component() {
  const escapeData = useLoaderData<typeof clientLoader>();
  return <InAppEscape escapeData={escapeData} />;
}
